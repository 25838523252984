<template>
  <!-- 交通信息 -->
  <div class="tabDetailWrap traffic">
    <div>
      <div class="tabDetail">
        <img class="tabLogo" :src="require('../../assets/image/traffic/tranffic_icon.png')" alt />
        <div class="logoRight">
          <span class="title">交通信息</span>
          <div class="subtitle">
            <img :src="require('../../assets/image/traffic/tranffic_subtitle.png')" alt />
          </div>
        </div>
        <!-- 路线详情 -->
        <div class="mapWrap">
          <img class="map" :src="require('../../assets/image/traffic/tranffic_bg.png')" alt />
        </div>
        <!-- 自驾路线 -->
        <div class="titleWrap group ziJia">
          <img :src="require('../../assets/image/traffic/title1.png')" alt />
          <!-- <img class="icon" :src="require('../../assets/image/traffic/route_icon.png')" alt />
          <span class="title">自驾路线</span>
          <img class="subtitle" :src="require('../../assets/image/traffic/route_subtitle.png')" alt />-->
        </div>
        <div class="routeDetail">
          <img class="bg" :src="require('../../assets/image/traffic/route_bg.png')" alt />
          <div class="groupWrap">
            <div class="infoGroup">
              <span class="detail" v-html="dataInfo.self_driving"></span>

              <!-- <span class="title">温州、丽水方向</span>
              <span class="detail">沈海高速至雁荡山出口下，途径104国道进入大荆镇，沿指示牌驾驶约10分钟到达。</span>-->
            </div>
            <!-- <div class="infoGroup">
              <span class="title">温州、丽水方向</span>
              <span class="detail">沈海高速至雁荡山出口下，途径104国道进入大荆镇，沿指示牌驾驶约10分钟到达。</span>
            </div>
            <div class="infoGroup">
              <span class="title">温州、丽水方向</span>
              <span class="detail">沈海高速至雁荡山出口下，途径104国道进入大荆镇，沿指示牌驾驶约10分钟到达。</span>
            </div>
            <div class="infoGroup">
              <span class="title">温州、丽水方向</span>
              <span class="detail">沈海高速至雁荡山出口下，途径104国道进入大荆镇，沿指示牌驾驶约10分钟到达。</span>
            </div>
            <div class="infoGroup">
              <span class="title">温州、丽水方向</span>
              <span class="detail">沈海高速至雁荡山出口下，途径104国道进入大荆镇，沿指示牌驾驶约10分钟到达。</span>
            </div>-->
          </div>
        </div>
        <!-- 公交路线 -->
        <div class="titleWrap group bus">
          <img :src="require('../../assets/image/traffic/title2.png')" alt />
          <!-- <img class="icon" :src="require('../../assets/image/traffic/bus_icon.png')" alt />
          <span class="title">公交路线</span>
          <img class="subtitle" :src="require('../../assets/image/traffic/bus_subtitle.png')" alt />-->
        </div>
        <div class="routeDetail">
          <img class="bg" :src="require('../../assets/image/traffic/bus_bg.png')" alt />
          <div class="groupWrap">
            <div class="infoGroup">
              <!-- <span class="title">雁荡山方向：</span> -->
              <span class="detail" v-html="dataInfo.bus"></span>
            </div>
            <!-- <div class="infoGroup">
              <span class="title">雁荡山方向：</span>
              <span
                class="detail"
              >乐清525路（雁荡山动车站上车-大荆客运中心下车），换乘512/515/516/517/518/519/520/521/523外环/532/535/536路（大荆客运中心上车——第五人名医院下车），沿指示牌步行1000米到达。</span>
            </div>
            <div class="infoGroup">
              <span class="title">温州、丽水方向</span>
              <span
                class="detail"
              >乘512/515/516/517/518/519/520/521/523外环/532/535/536路（大荆客运中心上车——第五人名医院下车），沿指示牌步行1000米到达。</span>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
export default {
  data() {
    return {
      dataInfo: {}
    };
  },
  mounted() {
    this.getData(config.serviceTraffic);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData[0];
            }

            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  .tabDetail {
    // padding-left: 40px;
    // padding-right: 40px;
    // padding-top: 50px;
    > .tabLogo {
      vertical-align: super;
      margin-right: 47px;
      width: 117px;
      height: 88px;
    }
    .logoRight {
      display: inline-block;
      > .title {
        font-size: 45px;
        font-weight: bold;
        color: $main-orange;
        margin-bottom: 20px;
      }
      > .subtitle {
        margin-top: 20px;
      }
    }

    > .mapWrap {
      height: 524px;
      margin-top: 77px;
      overflow: hidden;
    }
    > .titleWrap.group {
      // position: relative;
      // width: 817px;
      // height: 69px;
      // line-height: 69px;
      margin-top: 117px;
      // margin-left: 20px;
      // background: $main-orange;
      // border-radius: 30px;
      // box-sizing: border-box;
      // padding-left: 94px;
      .icon {
        position: absolute;
        left: -20px;
        top: -4px;
      }
      > .title {
        font-size: 36px;
        color: $main-white;
        font-weight: bold;
        vertical-align: super;
        margin-right: 34px;
      }
    }

    .routeDetail {
      position: relative;
      width: 1000px;
      height: 480px;
      margin-top: 28px;
      .groupWrap {
        position: absolute;
        width: 954px;
        height: 424px;
        overflow: hidden;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        .infoGroup {
          margin-top: 33px;
          > .title,
          > .detail {
            display: block;
          }
          > .title {
            font-size: 21px;
            font-weight: bold;
            color: $main-orange;
          }
          > .detail {
            margin-top: 12px;
            font-size: 15px;
            color: $main-black;
          }
        }
        .infoGroup:nth-of-type(1) {
          margin-top: 0;
        }
      }
    }

    .titleWrap.group.bus {
      // background: $main-blue;
      // .title {
      //   vertical-align: baseline;
      // }

      .routeDetail {
        .bg {
          width: 1000px;
          height: 301px;
        }
      }
    }
  }
}
</style>
